import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
//import '../../css/footer.css'
import '../../css/footer.css'

import '../../fonts/icomoon/style.css'


const PublicFooter = () => {
  const isAuth = true

  return (

    <div class="footer-14398">
       
     <div class="container">
       <div class="row mb-5">
         <div class="col-md-3">
           <a href="#" class="footer-site-logo">ZwickNet</a>
           <p>ZwickNet is currently in development. It is a personal software project, based in Australia. </p>
         </div>
         <div class="col-md-2 ml-auto">
           <h3>About</h3>
           <ul class="list-unstyled links">
             <li><a href="/contact">Contact</a></li>
             <li><a href="#">Projects*</a></li>
             <li><a href="https://github.com/zwicknet" target="_blank">Github <span class="icon-external-link"></span></a></li>
             <li><a href="#"></a></li>
           </ul>
         </div>
         <div class="col-md-2 ml-auto">
           <h3>Platform</h3>
           <ul class="list-unstyled links">
             <li><a href="/dashboard">Dashboard</a></li>
             <li><a href="#">Developer API*</a></li>
 
             <li><a href="#"></a></li>
             <li><a href="#"></a></li>
           </ul>
         </div>
         <div class="col-md-2 ml-auto">
           <h3>Support</h3>
           <ul class="list-unstyled links">
             <li><a href="#">Docs*</a></li>
             <li><a href="https://status.zwick.au/" target="_blank">Status <span class="icon-external-link"></span></a></li>
 
             <li><a href="#"></a></li>
             <li><a href="#"></a></li>
             <li><a href="#"></a></li>
           </ul>
         </div>
         <div class="col-md-2 ml-auto">
           <h3>Other Sites</h3>
           <ul class="list-unstyled links">
             <li><a href="https://www.zwick.au/" target="_blank">Zwick AU <span class="icon-external-link"></span></a></li>
             <li><a href="#"></a></li>
             <li><a href="#"></a></li>
             <li><a href="#"></a></li>
           </ul>
           <h3>ZwickNet - Legacy</h3>
           <ul class="list-unstyled links">
             <li><a href="https://accounts.zwick.au/realms/zwicknet/account" target="_blank">ZwickNet Account <span class="icon-external-link"></span></a></li>
             <li><a href="https://chat.zwick.au/" target="_blank">Chat - Legacy <span class="icon-external-link"></span></a></li>
             <li><a href="#">Portal - Discontinued <span class="icon-external-link"></span></a></li>
             <li><a href="#"></a></li>
             <li><a href="#"></a></li>
             <li><a href="#"></a></li>
           </ul>
         </div>
       </div>
 
       <div class="row mb-4">
         <div class="col-12 pb-4">
           <div class="line"></div>
         </div>
         <div class="col-md-6 text-md-left">
           <ul class="list-unstyled link-menu nav-left">
             <li><a href="/legal/terms-of-use/ZwickNet-terms-of-use-14-March-2024.pdf" target="_blank">Terms of Use</a></li>|
             <li><a href="#">Privacy Policy*</a></li>|
             <li><a href="#">Sitemap*</a></li>
           </ul>
         </div>
       </div>
 
       <div class="row">
         <div class="col-md-7">
           <p><small>By using this site, you acknowledge that you have read and agree to the ZwickNet <a target="blank" href="./legal/terms-of-use.pdf">Terms of Use</a>, and <a target="blank" href="/legal/privacy-policy">Privacy Policy</a>. Links followed by * don't point anywhere, so don't bother clicking them.</small></p>
         </div>
       </div>
     </div>
     
   </div>
      
  )
}

export default PublicFooter