import PublicLayout from "../../components/public-page-components/publicLayout";
import { Helmet } from "react-helmet";

//css files and photos
import '../../css/main.css' //main css file
import background from '../../assets/greyBackground.png'

const Alert = () => {
    alert("ZwickNet Service is not currently online. ")
}

const Home = () => {
    return (
        <PublicLayout>
            <Helmet>
                <title>ZwickNet</title>
            </Helmet>
        <div className="hero-image" style={{backgroundImage: `url(${background})`}}>
            <div className="hero-box">
                <h1>Welcome</h1>
                <p class="hero-box-warning">This website is currently under development! May not function as expected!</p>
                <div>
                    <a href="/dashboard">Login<span class="icon-external-link"></span></a>
                </div>
            </div>

        </div>
        <div className="content"><h1>Welcome</h1>
        <hi>ZwickNet is currently under construction. If you need to contact the website owner, please visit <a href="/contact">this</a> page.</hi>
        <p></p></div>


        </PublicLayout>

        
    );
};
  
  export default Home