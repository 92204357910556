import TopNavbar from './topNavbar'

const Layout = ({ children }) => {
  return (
    <div>
      <TopNavbar />
      <div>{children}</div>
    </div>
  )
}

export default Layout