import PublicNavbar from './publicNavbar'
import PublicFooter from './publicFooter'
import TopNavbar from '../topNavbar'
//<PublicNavbar />
const PublicLayout = ({ children }) => {
  return (
    <div>
      <TopNavbar/>
      <div>{children}</div>
      <PublicFooter />
    </div>
  )
}

export default PublicLayout