import { useSelector } from 'react-redux'
import { useState } from 'react'

import { unauthenticateUser } from '../redux/slices/authSlice'
import { useDispatch } from 'react-redux'
import { onLogout } from '../api/auth'

import '../css/navbar.css'
import waveLogo from '../assets/zwicknetCutOffWavelogo.png'

const TopNavbar = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [protectedData, setProtectedData] = useState(null)

  const logout = async () => {
    try {
      await onLogout()

      dispatch(unauthenticateUser())
      localStorage.removeItem('isAuth')
    } catch (error) {
      console.log(error.response)
    }
  }

  const { isAuth } = useSelector((state) => state.auth)

  return (
    <nav className='topNav'>
      <div className=''>
        <div>
            <a href="/">
            <img 
              src={waveLogo}
              alt="ZwickNet Logo"
              className="logo">
            </img>
            </a>
            <a href="/" className='logoText'>ZwickNet</a>
        </div>

        {isAuth ? (
          <div>
            <a href='/dashboard' className='topNavLink'>
              <span>Dashboard</span>
            </a>
            <div onClick={() => logout()} className='topNavButton'> Logout</div>
          </div>
        ) : (
          <div>
            <a href='/login' className='topNavButton'>
              <span>Login</span>
            </a>

            <a href='/register' className='topNavLink'>
              <span>Register</span>
            </a>
          </div>
        )}
      </div>
    </nav>
  )
}

export default TopNavbar