import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProtectedInfo } from '../api/auth'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

//css
import '../css/dashboard.css'

const Dashboard = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [protectedData, setProtectedData] = useState(null)

  const protectedInfo = async () => {
    try {
      const { data } = await fetchProtectedInfo()

      setProtectedData(data.info)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    protectedInfo()
  }, [])

  return loading ? (
    <Layout>
        <Helmet>
            <title>Dashboard | ZwickNet</title>
        </Helmet>
      <h1>Loading...</h1>
    </Layout>
  ) : (
    <div>
      <Layout>
        <div className='dashboard'>
          <h1>Dashboard</h1>
          <p>{protectedData}</p>
        </div>
      </Layout>
    </div>
  )
}

export default Dashboard